exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-src-content-projects-post-1-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.jsx?__contentFilePath=C:/Users/home/Projects/partnerr/local_repo/partnerr/src/content/projects/post-1/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-src-content-projects-post-1-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-src-content-projects-post-2-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.jsx?__contentFilePath=C:/Users/home/Projects/partnerr/local_repo/partnerr/src/content/projects/post-2/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-src-content-projects-post-2-index-mdx" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

